<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Ergonomic Analysis" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph>
          <p>
            Our SMS ergonomic analysis methodology provides practical solutions
            to ergonomic hazards encountered in a myriad of working conditions
            where cumulative trauma disorders (CTDs) / repetitive strain
            injuries (RSIs), acute soft tissue injuries, or other ergonomic
            concerns are present. We are experienced in providing ergonomic
            evaluations ranging from the office, chemical processing,
            manufacturing, mining, and explosives industries. Our ergonomic
            capabilities can assist safety, health, and engineering
            professionals in resolving ergonomic-related issues.
          </p>
          <p>
            The ergonomic analyses performed by SMS consist of three phases:
            process assessment, on-site analysis, and final documentation. The
            process assessment consists of a review of process design,
            equipment, previous analyses, and incident reports. The on-site
            analysis consists of video recording operations, measurement
            gathering (force, weights, reach distances, lifting heights, etc.),
            and performance of an Ergonomic Failure Modes and Effects Analysis
            (Ergonomic FMEA). The documentation phase addresses all operator and
            management comments and concerns.
          </p>
          <p>
            The Ergonomic FMEA consists of identification of potential ergonomic
            hazards, causes, and risk reducing factors. In addition ergonomic
            risk indexes, that have been developed by SMS, are used to
            prioritize the various hazards. Risk indexes have been developed for
            the three major ergonomic risk factors: (1) force, (2) posture, and
            (3) frequency. The force index is based on the NIOSH back
            compressive limits and the joint strength capability limits. The
            posture index is based on Rapid Upper Limb Assessment (RULA)
            methodology. The frequency, repetition, and duration index is based
            on the NIOSH lifting equation, accepted frequencies, and SMS
            experience. A fourth index which aids in identifying other
            contributing factors is also used.
          </p>
          <p>
            Each hazard scenario is ranked based upon the respective index. The
            indices are then used to generate and prioritize recommendations. A
            hazard with a less severe hazard index is given a lower priority and
            is not targeted with extensive and complex recommendations, while a
            hazard with a high risk index often has several associated
            recommendations that can be costly and involved.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Ergonomic Analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Our SMS ergonomic analysis methodology provides practical solutions to ergonomic hazards encountered in a myriad of working conditions where cumulative trauma disorders (CTDs) / repetitive strain injuries (RSIs), acute soft tissue injuries, or other ergonomic concerns are present."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
